import React, { useRef } from "react";
import { TCashiersSalesResponse, useCashierSalesQuery } from "../../../api/analytics";
import { Box, LoadingOverlay, Paper, Table, Text, useMantineColorScheme } from "@mantine/core";
import ReportHeader from "../ReportActions";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { formatAmount, formatQuantity } from "../report_utils";
import ReportPrint from "../ReportPrint";
import { groupBy, uniqBy } from "lodash";

const CashiersSalesAdmin = () => {
    const reportRef = useRef(null);
    const { t } = useTranslation();
    const selectedCashier = useAppSelector(state => state.reports.selectedCashier);
    const dates = useAppSelector(state => state.reports.datesRange);
    const isEqualDates = dayjs(dates[0]).format("DD-MM-YYYY") === dayjs(dates[1]).format("DD-MM-YYYY");
    const byPrices = useAppSelector(state => state.reports.byPrices);
    const byEvents = useAppSelector(state => state.reports.byEvents);
    const { data: report, isFetching } = useCashierSalesQuery({
        cashier: selectedCashier || 0,
        date_lte: dates[1] ? dayjs(dates[1]).format('YYYY-MM-DD') : undefined,
        date_gte: dates[0] ? dayjs(dates[0]).format('YYYY-MM-DD') : undefined,
        by_event: byEvents,
        by_price: byPrices

    }, {
        refetchOnMountOrArgChange: true,
        skip: !selectedCashier
    });


    const handlePrint = useReactToPrint({
        content: () => reportRef.current,
        copyStyles: true
    });

    return (
        <Box pos={"relative"}>
            <Paper p={10}>
                <ReportHeader
                    showCashierSelect
                    showPeriodSelect
                    showByEvents
                    showByPrices
                    dateType={"range"}
                />
            </Paper>
            {selectedCashier && (
                <Paper mt={10} pt={5} p={20} ref={reportRef} pos={"relative"}>
                    <ReportPrint handlePrint={handlePrint} />
                    <Box ta={"center"}>
                        <Text size={"30px"} pb={20}>{t("reports.ticket-sales.report")}</Text>
                        <Text size={"lg"}>
                            {t("reports.cashier-sales.boxOfficeReport")} {isEqualDates ? t("reports.by-period.toDate") : t("reports.by-period.period")}
                            <Text fw={600} span
                                c={"blue.9"}>  {isEqualDates ? dates[0] && dayjs(dates[0]).format("DD-MM-YYYY") : (dates[0] && dates[1]) && dayjs(dates[0]).format("DD-MM-YYYY") + ' - ' + dayjs(dates[1]).format("DD-MM-YYYY")}</Text>
                        </Text>
                        <Text size={"lg"}>{t("reports.cashier-sales.cashier")}: <Text fw={600} c={"blue.9"}
                            span>{report?.cashier.first_name} {report?.cashier.last_name}</Text></Text>
                    </Box>
                    <Box my={20}>
                        <CashierSalesTable data={report} />
                    </Box>
                </Paper>
            )}
            <LoadingOverlay visible={isFetching} />
        </Box>
    );
};

export default CashiersSalesAdmin;

const CashierSalesTable = (props: { data?: TCashiersSalesResponse }) => {
    const { t } = useTranslation();
    const { colorScheme } = useMantineColorScheme();
    const byPrices = useAppSelector(state => state.reports.byPrices);
    const uniqEvents = uniqBy(props.data?.rows, "event.id")

    const totals = props.data?.rows.reduce((acc, curr) => {
        acc = {
            sold_card_amount: acc.sold_card_amount += curr.sold_card_amount,
            sold_cash_amount: acc.sold_cash_amount += curr.sold_cash_amount,
            sold_quantity: acc.sold_quantity += curr.sold_quantity,
            refunded_quantity: acc.refunded_quantity += curr.refunded_quantity,
            refunded_amount: acc.refunded_amount += curr.refunded_amount,
            sold_quantity_realised: acc.sold_quantity - acc.refunded_quantity,
            revenue: acc.revenue += curr.revenue,

        };
        return acc;
    }, {
        sold_card_amount: 0,
        sold_cash_amount: 0,
        sold_quantity: 0,
        refunded_quantity: 0,
        refunded_amount: 0,
        sold_quantity_realised: 0,
        revenue: 0,
    });

    return (
        <Table withTableBorder withColumnBorders withRowBorders>
            <Table.Tbody>
                <Table.Tr>
                    {byPrices && (
                        <Table.Th rowSpan={2}>
                            {t("reports.table.price")}
                        </Table.Th>
                    )}

                    <Table.Th colSpan={3}>
                        {t("reports.table.sold")}
                    </Table.Th>
                    <Table.Th colSpan={2}>
                        {t("reports.table.refunded")}
                    </Table.Th>
                    <Table.Th rowSpan={2}>
                        {t("reports.table.realised")}
                    </Table.Th>
                    <Table.Th rowSpan={2}>
                        {t("reports.table.income")}
                    </Table.Th>
                    <Table.Th rowSpan={2}>
                        {t("reports.table.cashBoxIncome")}
                    </Table.Th>
                </Table.Tr>

                <Table.Tr>
                    <Table.Th>
                        {t("reports.table.quantity")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.payedCash")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.payedCard")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.quantity")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                </Table.Tr>

                {props.data?.rows.map((item, index) => {
                    const itemArr = Object.keys(item);
                    const isNextEvent = props.data?.rows[index - 1]?.event?.id !== item.event?.id;
                    const isEnd = props.data?.rows[index + 1]?.event?.id !== item.event?.id;
                    const grouped = groupBy(props.data?.rows, "event.id");
                    const defaultTotals = {
                        total_sold_quantity: 0,
                        total_sold_cash_amount: 0,
                        total_sold_card_amount: 0,
                        total_refunded_quantity: 0,
                        total_refunded_amount: 0,
                        total_revenue: 0,
                        total_sold_quantity_realised: 0
                    };
                    const groupTotals = item.event?.id ? grouped[item.event.id as keyof typeof grouped].reduce((acc, curr) => {
                        acc.total_sold_quantity += curr.sold_quantity || 0;
                        acc.total_sold_cash_amount += curr.sold_cash_amount || 0;
                        acc.total_sold_card_amount += curr.sold_card_amount || 0;
                        acc.total_refunded_quantity += curr.refunded_quantity || 0;
                        acc.total_refunded_amount += curr.refunded_amount || 0;
                        acc.total_revenue += curr.revenue || 0;
                        acc.total_sold_quantity_realised += curr.sold_quantity - curr.refunded_quantity

                        return acc;
                    }, defaultTotals) : defaultTotals;

                    const eventName = itemArr.map((key, i) => {
                        return key === "event" && isNextEvent && (
                            <Table.Tr bg={colorScheme === "dark" ? "dark.8" : "gray.0"} key={i}>
                                <Table.Td
                                    colSpan={item.event ? 7 : 1}><u>{item.event.name}</u>, {dayjs(item.event.start).format("DD-MM-YYYY")}
                                </Table.Td>
                            </Table.Tr>
                        );
                    });
                    const totals = (
                        <Table.Tr>
                            <Table.Th> {t("reports.table.total")} </Table.Th>
                            <Table.Th style={{ textAlign: "center" }}> {formatQuantity(groupTotals.total_sold_quantity || 0)}</Table.Th>
                            <Table.Th style={{ textAlign: "right" }}>{formatAmount(groupTotals.total_sold_cash_amount || 0)}</Table.Th>
                            <Table.Th style={{ textAlign: "right" }}>{formatAmount(groupTotals.total_sold_card_amount || 0)}</Table.Th>
                            <Table.Th style={{ textAlign: "center" }}> {formatQuantity(groupTotals.total_refunded_quantity || 0)}</Table.Th>
                            <Table.Th style={{ textAlign: "right" }}> {formatAmount(groupTotals.total_refunded_amount || 0)}</Table.Th>
                            <Table.Th style={{ textAlign: "center" }}> {formatQuantity(groupTotals.total_sold_quantity_realised || 0)}</Table.Th>
                            <Table.Th style={{ textAlign: "right" }}> {formatAmount(groupTotals.total_revenue || 0)}</Table.Th>
                            <Table.Th style={{ textAlign: "right" }}> {formatAmount((groupTotals?.total_sold_cash_amount || 0) - (groupTotals?.total_refunded_amount || 0))}</Table.Th>
                        </Table.Tr>
                    );

                    return (
                        <React.Fragment key={index}>
                            {eventName}
                            <Table.Tr>
                                {byPrices && (
                                    <Table.Td>{formatAmount(item.price)}</Table.Td>
                                )}
                                <Table.Td
                                    style={{ textAlign: "center" }}>{formatQuantity(item.sold_quantity)}</Table.Td>
                                <Table.Td
                                    style={{ textAlign: "right" }}>{formatAmount(item.sold_cash_amount)}</Table.Td>
                                <Table.Td
                                    style={{ textAlign: "right" }}>{formatAmount(item.sold_card_amount)}</Table.Td>
                                <Table.Td
                                    style={{ textAlign: "center" }}>{formatQuantity(item.refunded_quantity)}</Table.Td>
                                <Table.Td
                                    style={{ textAlign: "right" }}>{formatAmount(item.refunded_amount)}</Table.Td>
                                <Table.Td
                                    style={{ textAlign: "center" }}>{formatQuantity(item.sold_quantity - item.refunded_quantity)}</Table.Td>
                                <Table.Td
                                    style={{ textAlign: "right" }}>{formatAmount(item.revenue)}</Table.Td>
                                <Table.Td
                                    style={{ textAlign: "right" }}>{formatAmount((item?.sold_cash_amount || 0) - (item?.refunded_amount || 0))}</Table.Td>
                            </Table.Tr>
                            {(isEnd && byPrices) && totals}
                        </React.Fragment>
                    );
                })}
            </Table.Tbody>
            {uniqEvents.length > 1 && (<Table.Tfoot bg={colorScheme === "dark" ? "dark.8" : "gray.0"} style={{ borderTop: "1px solid lightgray" }}>
                <Table.Tr>
                    <Table.Th> Общий итого </Table.Th>
                    <Table.Th style={{ textAlign: "center" }}> {formatQuantity(totals?.sold_quantity || 0)}</Table.Th>
                    <Table.Th style={{ textAlign: "right" }}>{formatAmount(totals?.sold_cash_amount || 0)}</Table.Th>
                    <Table.Th style={{ textAlign: "right" }}>{formatAmount(totals?.sold_card_amount || 0)}</Table.Th>
                    <Table.Th style={{ textAlign: "center" }}>{formatAmount(totals?.refunded_quantity || 0)}</Table.Th>
                    <Table.Th style={{ textAlign: "right" }}>{formatAmount(totals?.refunded_amount || 0)}</Table.Th>
                    <Table.Th style={{ textAlign: "right" }}>{formatAmount(totals?.sold_quantity_realised || 0)}</Table.Th>
                    <Table.Th style={{ textAlign: "right" }}>{formatAmount(totals?.revenue || 0)}</Table.Th>
                    <Table.Th style={{ textAlign: "right" }}>{formatAmount((totals?.sold_cash_amount || 0) - (totals?.refunded_amount || 0))}</Table.Th>
                </Table.Tr>
            </Table.Tfoot>)}
        </Table>
    );
};
